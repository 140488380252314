<template>
  <div class="projects">
    <div class="project">
      <!-- header -->
      <div class="project__header_image">
        <img alt="Header Image" src="@/assets/images/header_home.png">  
      </div>
      <div class="project__header_content_box shadow-box">
        <h2>Ortsgruppe {{currentProject.organisationName}}</h2>
        <h4><a class="header_mail" v-bind:href="'mailto:'+currentProject.organisationEmail" target="_blank">{{currentProject.organisationEmail}}</a></h4>
      </div>    
      <!--content -->
      <span class="project__content" v-html="currentProject.pageContent"></span>
      <router-link id="press" class="bm-item-small next-link" to="/collectors">Zurück zur Übersicht</router-link>
    </div>
  </div>
</template>

<script>
import store from '@/store/store.js';

export default {
  name: 'CollectorsDetail',
  components: {
  },
  data() {
    return {
      store,
    };
  },
  computed: {
    currentProject: function () {
      return this.store.getCurrentSelectedCollector();
    }
  },
}
</script>

<style lang="scss">
  .header_mail{
    color: black!important;
    font-weight: normal;
    text-decoration: none;
  }
</style>
